/* reset styles can be used to override normalize or add box-sizing definition. */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

figure {
    margin: 0;
}

address {
    font-style: normal;
}

button {
    border-radius: 0; /* Remove border radius that chrome is adding on buttons */
}

/**
 * from http://players.brightcove.net/videojs-ima3/3.2.0/videojs.ima3.min.css
 * @brightcove/videojs-ima3
 * @version 3.2.0
 * @copyright 2018 Brightcove, Inc.
 * @license UNLICENSED
 */
.vjs-ad-playing.vjs-ad-playing .vjs-progress-control {
    pointer-events: none;
}
.vjs-ad-playing.vjs-ad-playing .vjs-play-progress {
    background-color: #ffe400;
}
.vjs-ad-loading .vjs-loading-spinner {
    display: block;
    visibility: visible;
}
.vjs-ad-playing .vjs-captions-button {
    display: none;
}
.vjs-ad-playing .vjs-audio-button {
    display: none;
}
.vjs-ad-loading .vjs-loading-spinner:before,
.vjs-ad-loading .vjs-loading-spinner:after {
    -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8)
            infinite,
        vjs-spinner-fade 1.1s linear infinite;
    animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
        vjs-spinner-fade 1.1s linear infinite;
}
.vjs-ad-loading .vjs-loading-spinner:before {
    border-top-color: #fff;
}
.vjs-ad-loading .vjs-loading-spinner:after {
    border-top-color: #fff;
    -webkit-animation-delay: 0.44s;
    animation-delay: 0.44s;
}
.vjs-ad-loading .vjs-big-play-button,
.vjs-ad-loading .vjs-poster,
.vjs-ad-content-resuming .vjs-big-play-button,
.vjs-ad-content-resuming .vjs-poster {
    display: none;
}
.vjs-ima3-ad-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.vjs-ima3-html5 .vjs-ima3-ad-container > *,
.vjs-ima3-html5 .vjs-ima3-ad-container > * iframe {
    width: 100% !important;
    height: 100% !important;
}
.vjs-ima3-ad-container,
.vjs-ima3-click-tracker {
    pointer-events: none;
    left: -9999px;
}
.vjs-ima3-ad-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
}
.vjs-ad-playing .vjs-ima3-ad-background {
    background-color: #000;
}
.vjs-ad-playing .vjs-ima3-ad-container,
.vjs-ad-playing .vjs-ima3-click-tracker,
.vjs-ima3-overlay .vjs-ima3-ad-container,
.vjs-ima3-overlay .vjs-ima3-click-tracker {
    left: 0;
}
.vjs-mouse.vjs-ima3-html5.vjs-ad-playing .vjs-ima3-ad-container,
.vjs-mouse.vjs-ima3-html5.vjs-ad-playing .vjs-ima3-click-tracker,
.vjs-mouse.vjs-ima3-html5.vjs-ima3-overlay .vjs-ima3-ad-container,
.vjs-mouse.vjs-ima3-html5.vjs-ima3-overlay .vjs-ima3-click-tracker {
    cursor: pointer;
    pointer-events: auto;
}
.vjs-user-active.vjs-ad-playing.vjs-ima3-html5 .vjs-ima3-ad-container,
.vjs-user-active.vjs-ad-playing.vjs-ima3-html5 .vjs-ima3-click-tracker,
.vjs-user-active.vjs-ima3-overlay.vjs-ima3-html5 .vjs-ima3-ad-container,
.vjs-user-active.vjs-ima3-overlay.vjs-ima3-html5 .vjs-ima3-click-tracker {
    cursor: pointer;
    pointer-events: auto;
}
.vjs-ima3-html5.vjs-using-native-controls.vjs-ima3-paused.vjs-ima3-paused
    .vjs-ima3-ad-container,
.vjs-ima3-html5.vjs-using-native-controls.vjs-ima3-paused.vjs-ima3-paused
    .vjs-ima3-click-tracker {
    pointer-events: none;
}
.vjs-ima3-click-tracker {
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.vjs-ima3-overlay .vjs-ima3-click-tracker {
    top: auto;
    margin: 0 auto;
}
.vjs-control-bar.vjs-control-bar.vjs-ad-control-bar.vjs-ad-control-bar {
    display: none;
}
.vjs-ad-controls.vjs-ad-playing .vjs-control-bar.vjs-control-bar {
    display: none;
}
.vjs-ad-controls.vjs-ad-playing.vjs-using-native-controls
    .vjs-ad-control-bar.vjs-ad-control-bar,
.vjs-ad-controls.vjs-ad-playing.vjs-controls-disabled
    .vjs-ad-control-bar.vjs-ad-control-bar {
    display: none;
}
.vjs-ad-controls.vjs-ad-playing.vjs-user-active:not(.not-hover)
    .vjs-control-bar.vjs-ad-control-bar {
    -ms-transform: none;
    -ms-transition: none;
    transform: none;
    transition: none;
}
.vjs-ad-playing .vjs-dock-text,
.vjs-ad-playing .vjs-dock-shelf {
    display: none;
}
.vjs-ad-controls.vjs-ad-playing.vjs-vpaid-playing.vjs-vpaid-controls-disabled
    .vjs-control-bar.vjs-ad-control-bar {
    display: none;
}
.vjs-ad-loading .vjs-overlay,
.vjs-ad-playing .vjs-overlay {
    display: none;
}
.vjs-ad-controls.vjs-ad-playing .vjs-control-bar.vjs-ad-control-bar {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.vjs-ad-controls.vjs-ad-playing.vjs-no-flex
    .vjs-control-bar.vjs-ad-control-bar {
    display: table;
}
.vjs-mouse .vjs-control-bar.vjs-ad-control-bar .vjs-progress-control:hover {
    height: 0.5em;
    top: -0.5em;
}
.vjs-mouse
    .vjs-control-bar.vjs-ad-control-bar
    .vjs-progress-control:hover
    .vjs-mouse-display,
.vjs-mouse
    .vjs-control-bar.vjs-ad-control-bar
    .vjs-progress-control:hover
    .vjs-mouse-display:after,
.vjs-mouse .vjs-progress-control:hover .vjs-play-progress:after {
    display: none;
}
.vjs-ima3-html5.vjs-ad-loading .vjs-tech {
    opacity: 0;
}
.vjs-ima3-autoplay.vjs-ima3-not-playing-yet .vjs-poster,
.vjs-ima3-autoplay.vjs-ima3-not-playing-yet .vjs-tech {
    opacity: 0;
}
.vjs-ima3-html5.vjs-ad-playing .vjs-ad-control-bar .vjs-play-progress {
    transition: width 1s linear;
}
.vjs-ima3-html5.vjs-ad-playing
    .vjs-ad-control-bar
    .vjs-play-progress.vjs-play-progress-resetting {
    transition: width 0s linear;
}
